.cta {
    background: #FFF;
    border-radius: 50px;
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    padding: 0 8px;
    margin: 12px 0;
    cursor: pointer;
}

.cta-icon {
    height: 55px;
    aspect-ratio: 1 / 1;
    text-align: center;
}

.cta-icon img {
    height: 60%;
    padding: 20% 0;
}

.cta-text {
    color: #575294;
    font-weight: bold;
    font-size: 14px;
}
