@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}

:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif; }
}

html {
    min-height: 100%;
}

body {
    background: #fff !important;
    font-family: ui-rounded, -apple-system, 'Open Sans', sans-serif  !important;
    padding: 20px !important;
    height: 100%;
    margin: 0;
}

.device-ios body {
    padding: calc(env(safe-area-inset-top) + 20px) 20px calc(env(safe-area-inset-bottom) + 20px) !important;
}

.device-android body {
    padding: 20px 20px 150px !important;
}

body:has(.view) {
    background: #f0ebf8 !important;
}

.device-android body:has(.view) {
    background: #f0ebf8 !important;
}

.device-android .view {
    padding-bottom: 5px;
}

@supports (-webkit-touch-callout: none) {
    body {
        min-height: 850px;
    }
}

.title {
    font-family: ui-rounded, -apple-system, 'Inter', sans-serif  !important;
    font-size: 34px;
    font-weight: bold;
    margin: 16px 0;
}

.subtitle {
    font-size: 20px;
    font-weight: bold;
}

.text {
    font-size: 16px;
}

.action-text {
    color: #575294;
    font-weight: bold;
    font-size: 14px;
}

.center {
    text-align: center;
}

.divider {
    height: 1px;
    background: #ddd;
    margin: 16px 0;
}

.nav-bar {
}

.nav-bar-link {
    color: #afa9ee;
    font-weight: bold;
}

.icon {
    display: inline-block;
    background: #fff;
    height: 80%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    text-align: center;
}

.icon img {
    vertical-align: middle;
    height: 60%;
}

.icon-small {
    display: inline-block;
    background: #fff;
    height: 60%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

.icon-small img {
    vertical-align: middle;
    height: 60%;
}

.icon-alignment-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.content-container {
    background: #fff;
    border-radius: 15px;
    padding: 8px;
    margin: 20px 0;
}

.vmr .mdhui-loading-indicator {
    color: #575294;
}

input {
    font-family: ui-rounded, -apple-system, 'Open Sans', sans-serif !important;
}